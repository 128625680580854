import React from 'react';

interface ProductProps {
    highlighted: boolean;
    imageUrl: string;
    title: string;
    price: string;
    strikethroughPrice?: string;
    productUrl: string;
    index: string;
}

const HighlightProduct: React.FC<ProductProps> = ({
    highlighted,
    imageUrl,
    title,
    price,
    strikethroughPrice,
    productUrl,
    index,
}) => {
    return (

        <div
            style={{
                // border: highlighted ? '2px solid red' : '1px solid grey',
                padding: '5px 10px',
                margin: '5px 30px',
                display: 'flex',
                alignItems: 'flex-start',
                background: '#f7f1f0',
                borderRadius: '5px',
                border: '3px solid #6f1920',
                placeItems: 'center',
                justifyContent: 'space-evenly',
            }}
        >
            {/* Number Badge */}
            <div
                style={{
                    // position: 'absolute',
                    top: 0,
                    left: 0,
                    // width: '30px',
                    // height: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    background: '#6f1920',
                    borderRadius: '5px',
                    color: 'white',
                    padding: '3px',
                    marginRight: '10px',
                    height: '30px',
                    width: '30px'
                }}
            >
                {index} {/* Displaying index starting from 1 */}
            </div>
            <img src={imageUrl} alt={title} style={{ width: '50px', height: 'auto' }} />
            <div style={{ margin: '5px 15px' }}>
                <h4 style={{ fontSize: '12px', marginBottom: '0' }}>{title}</h4>
                <p style={{ fontSize: '13px', marginBottom: '0' }}>
                    <span style={{ textDecoration: 'line-through', color: '#bcbcbc' }}>RM{price}</span>
                    {'  '}
                    {strikethroughPrice && (
                        <span style={{ color: '#6f1920' }}>
                            RM{strikethroughPrice}
                        </span>
                    )}
                </p>
                <a
                    href={productUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                >
                    <button style={{ cursor: 'pointer', background: '#6f1920', padding: '5px 20px', borderRadius: '5px', color: 'white', width: '100%' }}>Buy Now</button>
                </a>
            </div>
        </div>
    );
};

export default HighlightProduct;
